import {
    FilterAmlNames,
    FilterApplicantAlertsNames,
    FilterApplicantDocsNames,
    FilterApplicantOrganizationNames,
    FilterApplicantPersonNames,
    FilterBillingNames,
    FilterDataSourcesNames,
    FilterDocsVerNames,
} from '@models/enums';
import { adminPanelApi } from '@modules/admin-panel/api';
import { adminPanelReducer } from '@modules/admin-panel/store';
import { authSlice } from '@modules/auth/store';
import { dashboardApi } from '@modules/dashboard/api/dashboard-base-api';
import { sdkApi } from '@modules/docs-verification-widget/api/sdk-base-api';
import { publicApi } from '@modules/shared/api/public-base-api';
import { domainSlice } from '@modules/shared/slices/domain-slice';
import { combineReducers } from '@reduxjs/toolkit';
import {
    applicantApplyModalSlice,
    applicantDocsFiltersSlice,
    applicantDocsVerificationFiltersSlice,
    applicantEditModalSlice,
    applicantOrganizationFiltersSlice,
    applicantPepHistoryFiltersSlice,
    applicantPersonFiltersSlice,
} from '@store/slices/applicant';
import { applicantAlertsFiltersSlice } from '@store/slices/applicant/applicant-alerts-filters-slice';
import { billingOperationsFiltersSlice } from '@store/slices/billing/billing-operations-filters-slice';
import { billingPaymentsFiltersSlice } from '@store/slices/billing/billing-payments-filters-slice';
import {
    docsVerificationFiltersSlice,
    docsVerificationSlice,
    searchApplicantFiltersSlice,
} from '@store/slices/docs-verification';
import { logoutSlice } from '@store/slices/logout-slice';
import {
    dataSourcesFiltersSlice,
    pepFiltersSlice,
    pepHistoryFiltersSlice,
} from '@store/slices/pep';

import { appSlice } from './slices/app-slice';
import { infiniteScrollTableSlice } from './slices/infinite-scroll-table-slice';
import { operationHistorySlice } from './slices/operation-history-slice';
import { sessionSlice } from './slices/session-slice';
import { themeSlice } from './slices/theme-slice';
import { toastSlice } from './slices/toast-slice';

export const rootReducer = combineReducers({
    domain: domainSlice.reducer,
    theme: themeSlice.reducer,
    auth: authSlice.reducer,
    toast: toastSlice.reducer,
    logout: logoutSlice.reducer,
    infinityScrollTable: infiniteScrollTableSlice.reducer,
    docsVerification: docsVerificationSlice.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    dashboard: combineReducers({
        app: appSlice.reducer,
        [FilterDocsVerNames.DOCS_VERIFICATION_FILTERS_SLICE]: docsVerificationFiltersSlice.reducer,
        [FilterDataSourcesNames.DATA_SOURCES_FILTERS_SLICE]: dataSourcesFiltersSlice.reducer,
        session: sessionSlice.reducer,
        operationHistory: operationHistorySlice.reducer,
        pepFilters: pepFiltersSlice.reducer,
        [FilterAmlNames.PEP_HISTORY_FILTERS_SLICE]: pepHistoryFiltersSlice.reducer,
        [FilterBillingNames.BILLING_OPERATIONS_FILTERS_SLICE]:
            billingOperationsFiltersSlice.reducer,
        [FilterBillingNames.BILLING_PAYMENTS_FILTERS_SLICE]: billingPaymentsFiltersSlice.reducer,
        [FilterApplicantPersonNames.SEARCH_APPLICANT_FILTERS_SLICE]:
            searchApplicantFiltersSlice.reducer,
        [FilterDocsVerNames.APPLICANT_DOCS_VERIFICATION_FILTERS_SLICE]:
            applicantDocsVerificationFiltersSlice.reducer,
        [FilterAmlNames.APPLICANT_PEP_HISTORY_FILTERS_SLICE]:
            applicantPepHistoryFiltersSlice.reducer,
        [FilterApplicantPersonNames.APPLICANT_PERSON_FILTERS_SLICE]:
            applicantPersonFiltersSlice.reducer,
        [FilterApplicantOrganizationNames.APPLICANT_ORGANIZATION_FILTERS_SLICE]:
            applicantOrganizationFiltersSlice.reducer,
        [FilterApplicantAlertsNames.APPLICANT_ALERTS_FILTERS_SLICE]:
            applicantAlertsFiltersSlice.reducer,
        applicantEditModalSlice: applicantEditModalSlice.reducer,
        applicantApplyModalSlice: applicantApplyModalSlice.reducer,
        [FilterApplicantDocsNames.APPLICANT_DOCS_FILTERS_SLICE]: applicantDocsFiltersSlice.reducer,
    }),
    [adminPanelApi.reducerPath]: adminPanelApi.reducer,
    adminPanel: adminPanelReducer,
    [sdkApi.reducerPath]: sdkApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
